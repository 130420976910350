
import gcbj from "../public/gcbj.png"
import gcbj_zyzbj from "../public/gcbj_zyzbj.png"
import gcbj_zyzlx from "../public/gcbj_zyzlx.png"
import gcbj_zyzzc from "../public/gcbj_zyzzc.png"
import gclx_zyzbj from "../public/gclx_zyzbj.png"
import gclx_zyzlx from "../public/gclx_zyzlx.png"
import gclx_zyzzc from "../public/gclx_zyzzc.png"
import gczc from "../public/gczc.png"
import gczc_zyzbj from "../public/gczc_zyzbj.png"
import gczc_zyzlx from "../public/gczc_zyzlx.png"
import gczc_zyzzc from "../public/gczc_zyzzc.png"
import lx from "../public/lx.png"
import station_close from "../public/station_close.png"
import washroom_mend from "../public/washroom_mend.png"
import zyzbj from "../public/zyzbj.png"
import zyzlx from "../public/zyzlx.png"
import zyzzc from "../public/zyzzc.png"

const Color = ["#B4B4B4", "#06D300", "#FF4940"];

// const Type = {
//     // 公厕
//     1: {
//         // 0未开启 1正常 2报警
//         0: washroom_close,
//         1: washroom_open,
//         2: washroom_warn,
//     },
//     // 转运站
//     2: {
//         0: station_close,
//         1: station_open,
//         2: station_warn,
//     },
// };

export default class MapIconOverlay extends window.BMap.Overlay {
    // 坐标点、文字、点击回调，类型，状态
    constructor(point, text, fn, fns, fnss, type, state, transferPointStatus, workStatus, manStatus, womanStatus) {
        super();
        this._point = point;
        this._text = text;
        this._fn = fn;
        this._fns = fns;
        this._fnss = fnss;
        this._type = type; // 站点类型
        this._state = state;  // 公厕状态
        this._transferPointStatus = transferPointStatus;// 转运站状态
        this._workStatus = workStatus;// 工作状态
        this._manStatus = manStatus  // 男公厕状态
        this._womanStatus = womanStatus // 女公厕状态
    }
    initialize(map) {
        let urlImg
        //首页图标状态
        if (this._type==1) {
            if (this._workStatus == 3) {
                urlImg = washroom_mend // 维修
            }else if (this._workStatus == 2) {
                urlImg = station_close // 未开启
            }else if (this._workStatus ==1) {
                // if (this._state == 0) {
                //     urlImg = station_close // 公厕未开启
                // } else if (this._state == 2 &&  this._womanStatus ==0) {
                //     urlImg = station_warn //  报警-女厕离线
                // } else if (this._state ==2 && this._manStatus ==0) {
                //     urlImg = station_open  // 报警-男厕离线
                // } else if (this._state == 2 && this._womanStatus == 2) {
                //     urlImg = stand_open // 公厕报警-女厕报警
                // } else if (this._state == 2 && this._manStatus ==2) {
                //     urlImg = stand_close //公厕报警-男厕报警
                // } else if (this._state == 1 && this._manStatus ==0) {
                //     urlImg = stand_warn // 男厕离线
                // } else if (this._state == 1 && this._womanStatus ==0) {
                //     urlImg = washroom_close // 女厕离线
                // } else if (this._state == 1 && this._manStatus ==2 ) {
                //     urlImg = station_warn
                // }
                if (this._state == 0) {
                    urlImg = lx 
                } 
                else if (this._state == 2 && this._womanStatus == 0) {
                    urlImg = gcbj
                } 
                else if (this._state == 2 && this._manStatus == 0) {
                    urlImg = gcbj 
                } 
                else if (this._state == 2 && this._womanStatus == 2) {
                    urlImg = gcbj
                } 
                else if (this._state == 2 && this._manStatus == 2) {
                    urlImg = gcbj

                } 
                else if (this._state == 1 && this._manStatus == 0) {
                    urlImg = gczc
                } 
                else if(this._manStatus == 1 && this._womanStatus == 1){
                    urlImg = gczc
                }
                else if (this._state == 1 && this._womanStatus == 0) {
                    urlImg = gczc
                } 
                else if (this._state == 1 && this._manStatus == 2) {
                    urlImg = gcbj 
                }
            }
            
        } else if (this._type==2) {
            // if (this._transferPointStatus ==2 && this._manStatus ==0) {
            //     urlImg = washroom_warn  //转运站报警-男厕离线
            // } else if (this._transferPointStatus ==2 && this._womanStatus ==0) {
            //     urlImg = stationWashroom_01  //转运站报警-女厕离线
            // } else if (this._transferPointStatus ==0 && this._state == 2 && this._manStatus ==0) {
            //     urlImg = stationWashroom_02  //转运站离线-公厕报警-男厕离线
            // } else if (this._transferPointStatus ==0 && this._state == 2 && this._womanStatus ==0) {
            //     urlImg = stationWashroom_10 //转运站离线-公厕报警女厕离线 
            // } else if (this._transferPointStatus ==0 && this._manStatus ==0) {
            //     urlImg = stationWashroom_12 //转运站离线-男厕离线
            // } else if (this._transferPointStatus ==0 && this._womanStatus ==0) {
            //     urlImg = stationWashroom_20 //转运站离线-女厕离线
            // } else if (this._transferPointStatus ==1 && this._manStatus ==0) {
            //     urlImg = stationWashroom_21 //转运站-男厕离线
            // } else if (this._transferPointStatus ==1 && this._womanStatus ==0) {
            //     urlImg = stationWashroom_25 //转运站-女厕离线
            // }
            if(this._workStatus == 3){
                urlImg = washroom_mend
            }else if(this._workStatus == 2){
                urlImg = station_close
            }else if(this._workStatus == 1){
                if(this._transferPointStatus == 2 && this._manStatus == 0 && this._womanStatus == 0){ //公厕离线转运站报警
                    urlImg = gcbj_zyzbj
                }
                else if (this._transferPointStatus == 2 &&this._state ==2 &&this._manStatus == 2) { //公厕报警转运站报警
                    urlImg =gcbj_zyzbj
                }
                else if (this._transferPointStatus == 2&&this._state ==2  && this._womanStatus == 2) { //公厕报警转运站报警
                    urlImg = gcbj_zyzbj
                }
                else if(this._transferPointStatus == 2 && this._womanStatus == 1 && this._state == 1){ //公厕正常转运站报警
                    urlImg =gczc_zyzbj
                }
                else if(this._transferPointStatus == 2 && this._manStatus == 1 && this._state == 1){ //公厕正常转运站报警
                    urlImg = gczc_zyzbj
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 1 && this._womanStatus == 2){
                    urlImg = gczc_zyzlx
                }
                else if (this._transferPointStatus == 2 && this._manStatus == 0) { //公厕正常转运站报警
                    urlImg = gczc_zyzbj
                } 
                else if (this._transferPointStatus == 2 && this._womanStatus == 0) { //公厕正常转运站报警
                    urlImg = gczc_zyzbj
                } 
                 else if (this._transferPointStatus == 0 && this._womanStatus ==1 &&this._manStatus == 0) { //公厕正常转运站离线
                    urlImg = gczc_zyzlx
                } 
                else if (this._transferPointStatus == 0 && this._manStatus == 1 && this._womanStatus == 0) { //公厕正常转运站离线
                    urlImg = gczc_zyzlx
                } 
                else if(this._transferPointStatus == 0 && this._manStatus == 1 && this._womanStatus == 1){ //公厕正常转运站离线
                    urlImg = gczc_zyzlx
                }
                else if (this._transferPointStatus == 0 && this._manStatus == 0  && this._womanStatus == 0) { //公厕离线转运站离线
                    urlImg =gclx_zyzlx
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 2  && this._womanStatus == 0){ //
                    urlImg = gcbj_zyzlx
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 2  && this._womanStatus == 1){
                    urlImg = gcbj_zyzlx
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 1  && this._womanStatus == 2){
                    urlImg = gcbj_zyzlx
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 0  && this._womanStatus == 2){ //
                    urlImg = gcbj_zyzlx
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 2  && this._womanStatus == 0){ //
                    urlImg = gcbj_zyzlx
                }
                else if(this._transferPointStatus == 0 && this._manStatus == 2  && this._womanStatus == 2){
                    urlImg = gcbj_zyzlx
                }
                else if(this._transferPointStatus == 1 && this._womanStatus == 0 && this._manStatus == 1 ){
                    urlImg = gczc_zyzzc
                }
                else if(this._transferPointStatus == 1 && this._womanStatus == 1 && this._manStatus == 0 ){
                    urlImg = gczc_zyzzc
                }
                else if(this._transferPointStatus == 1 && this._manStatus == 0 && this._womanStatus == 0){ //公厕离线转运站正常
                    urlImg = gclx_zyzzc
                }
                else if(this._transferPointStatus == 1 && this._manStatus == 1 && this._womanStatus == 1){
                    urlImg = gczc_zyzzc
                }
                else if(this._transferPointStatus == 1 && this._manStatus == 2 ){
                    urlImg = gcbj_zyzzc
                }
                else if(this._transferPointStatus == 1 && this._womanStatus == 2 ){
                    urlImg = gcbj_zyzzc
                }
                
            }
        } else if (this._type == 3) {
            if (this._transferPointStatus == 1) {
                urlImg = zyzzc
            } else if (this._transferPointStatus == 0) {
                urlImg = zyzlx
            }else if(this._transferPointStatus == 2){
                urlImg = zyzbj
            }
        }

        
        this._map = map;
        // 容器div
        let container = document.createElement("div");
        this._dom = container;
        container.style.position = "absolute";
        container.style.display = "flex";
        container.style.alignItems = "flex-start";
        container.style.justifyContent = "center";
        container.style.cursor = "pointer";
        // 图标img
        let img = document.createElement("img");
        this._img = img;
        img.src = urlImg;
        img.style.position = "relative";
        img.style.zIndex = "1";
        container.appendChild(img);
        // 文字span;
        let span = document.createElement("span");
        this._span = span;
        span.textContent = this._text;
        span.style.border = "solid 2px " + Color[this._state];
        span.style.border = "solid 2px " + Color[this._transferPointStatus];
        span.style.borderRadius = "4px";
        span.style.backgroundColor = "white";
        span.style.marginLeft = "-4px";
        span.style.lineHeight = "1";
        span.style.padding = "4px";
        span.style.whiteSpace = "nowrap";
        let boxDiv = document.createElement("div");
        boxDiv.style.width = "100%";
        boxDiv.style.height = "100%";
        boxDiv.style.position = "absolute";
        boxDiv.style.zIndex = "666";
        container.appendChild(boxDiv);
        container.addEventListener("mouseover", this._fns);
        container.addEventListener("mouseout", this._fnss);
        container.addEventListener("click", this._fn);
        container.appendChild(span);
        // 添加到地图层
        map.getPanes().labelPane.appendChild(container);
        // 绑定点击事件

        return container;
    }
    draw() {
        let map = this._map;
        let pixel = map.pointToOverlayPixel(this._point);
        this._dom.style.left = pixel.x + "px";
        this._dom.style.top = pixel.y + "px";
    }
    // change(type, state, text) {
    //     this._img.src = Type[type][state];
    //     this._span.style.border = "solid 2px " + Color[state];
    //     this._span.textContent = text;
    // }
}
